import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-textarea',
  template: `
  <div class="custom-input"
      [ngClass]="{
        'required-input-field': props.required,
        'disabled-input-field': formControl.disabled,
        'valid-input-field': !formControl.invalid && formControl.touched
      }">
    <mat-label [matTooltip]="props.toolTip ?? 'props.description'" matTooltipPosition="after">{{props.label}}</mat-label>
    <textarea
      matInput
      [formControl]="formControl"
      [cols]="props.cols"
      [rows]="props.rows"
      [placeholder]="props.placeholder ?? ''"
      [formlyAttributes]="field"
    ></textarea>
</div>
  `,
})
export class CustomTextAreaComponentType extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }
}

export const customTextAreaType = {
  name: 'custom-textarea',
  component: CustomTextAreaComponentType,
};
